import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CustomersQuery } from '@memberspot/admin/shared/data-access/customers';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { UserTrackingService } from '@memberspot/frontend/shared/user-tracking';
import {
  AdminOnboarding,
  AdminOnboardingEndpoints,
  GUIDE_VERSION,
  OnboardingTask,
} from '@memberspot/shared/model/admin-onboarding';
import { HotToastService } from '@ngneat/hot-toast';
import { injectQuery, injectQueryClient } from '@ngneat/query';
import { TranslocoService } from '@ngneat/transloco';
import { Dict } from 'mixpanel-browser';
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  mergeMap,
} from 'rxjs';

const GUIDE_DISMISSIBLE_AFTER_6_WEEKS = 1000 * 6 * 7 * 24 * 60 * 60;

@Injectable()
export class AdminOnboardingService {
  private readonly _userTrackingService = inject(UserTrackingService);
  private readonly _query = injectQuery();
  private readonly _queryClient = injectQueryClient();
  private readonly _http = inject(HttpBackendService);
  private readonly _schoolsQuery = inject(SchoolsQuery);
  private readonly _schoolId$ = this._schoolsQuery.selectActiveId();
  private readonly _toast = inject(HotToastService);
  private readonly _translocoService = inject(TranslocoService);
  private readonly _customerQuery = inject(CustomersQuery);
  private readonly _customer = toSignal(this._customerQuery.selectActive());

  private readonly _query$ = this._schoolId$.pipe(
    filter(Boolean),
    distinctUntilChanged(),
    mergeMap(
      (schoolId) =>
        this._query<AdminOnboarding>({
          queryFn: () =>
            this._http.generic(
              AdminOnboardingEndpoints.GET_ONE(
                schoolId,
                GUIDE_VERSION.GUIDE_2024_08_19,
              ),
            ),
          retry: 0,
          queryKey: ['admin-onboarding', schoolId],
          staleTime: 1000 * 60,
        }).result$,
    ),
  );

  public data = toSignal(this._query$.pipe(map((res) => res.data)));

  public progress = computed(() => {
    const total = this.data()?.guide.tasks.length ?? 0;

    const completed =
      this.data()?.guide.tasks.filter(({ taskCompleted }) => taskCompleted)
        .length ?? 0;

    const ratio = completed / total;
    const percent = Number.isNaN(ratio) ? 0 : (ratio * 100).toFixed(0);

    return {
      total,
      completed,
      percent,
    };
  });

  active = computed(
    () =>
      !this.data()?.hidden &&
      this.progress().total !== this.progress().completed,
  );

  public groups = computed(() => {
    const grouped =
      this.data()?.guide.tasks.reduce(
        (groups, task) => {
          if (groups[task.groupId]) {
            groups[task.groupId].tasks.push(task);
          } else {
            groups[task.groupId] = {
              groupId: task.groupId,
              groupTitle: task.groupTitle,
              groupIcon: task.groupIcon,
              groupOrder: task.groupOrder,
              tasks: [task],
            };
          }

          return groups;
        },
        {} as Record<
          string,
          Pick<
            OnboardingTask,
            'groupId' | 'groupIcon' | 'groupOrder' | 'groupTitle'
          > & { tasks: OnboardingTask[] }
        >,
      ) ?? {};

    return Object.values(grouped);
  });

  public firstIncompleteTask = computed(
    () =>
      this.data()?.guide.tasks.reduce((current, task) => {
        if (current.taskCompleted) {
          return task;
        }

        if (task.taskCompleted) {
          return current;
        }

        return task.groupOrder <= current.groupOrder &&
          task.taskOrder < current.taskOrder
          ? task
          : current;
      }),
  );

  public guideDismissible = computed(() => {
    const customer = this._customer();

    if (!customer) {
      return false;
    }

    const now = new Date().getTime();
    const startDate = customer.startDate.toDate().getTime();

    // age of the customer account
    const diff = now - startDate;

    return diff > GUIDE_DISMISSIBLE_AFTER_6_WEEKS;
  });

  public create() {
    const schoolId = this._schoolsQuery.getActiveId();

    if (!schoolId) {
      return;
    }

    this._http
      .generic(
        AdminOnboardingEndpoints.CREATE(schoolId, {
          version: GUIDE_VERSION.GUIDE_2024_08_19,
        }),
      )
      .subscribe();
  }

  public dropOff() {
    const schoolId = this._schoolsQuery.getActiveId();
    const version = this.data()?.guide.version;

    if (!schoolId || !version) {
      return;
    }

    return firstValueFrom(
      this._http.generic(AdminOnboardingEndpoints.DROP_OFF(schoolId, version)),
    );
  }

  public async markTaskAsCompleted(taskId: string, completed: boolean = true) {
    if (this.data()?.hidden || this.progress().percent === 100) {
      // guide completed or user dropped off
      return;
    }

    const schoolId = this._schoolsQuery.getActiveId();
    const version = this.data()?.guide.version;

    if (!schoolId || !version) {
      return;
    }

    const task = this.data()?.guide.tasks.find(
      (_task) => _task.taskId === taskId,
    );

    if (!task) {
      // taskId not known
      return;
    }

    if (task.taskCompleted && completed) {
      // task already done
      return;
    }

    await firstValueFrom(
      this._http.generic(
        AdminOnboardingEndpoints.MARK_AS_COMPLETED(schoolId, {
          taskId,
          version,
          completed,
        }),
      ),
    );
    await this._queryClient.invalidateQueries({
      queryKey: ['admin-onboarding', schoolId],
    });

    const taskTranslationKey = `onboarding.${version}.task.${taskId}.title`;

    const taskTitle = this._translocoService.translate(taskTranslationKey);
    const message = this._translocoService.translate(
      'onboarding.completedMessage',
      { task: taskTitle },
    );

    this._toast.info(message);
  }

  trackEvent(event: string, additionalProps: Dict = {}) {
    this._userTrackingService.trackEvent(event, {
      schoolId: this.data()?.schoolId,
      version: this.data()?.guide.version,
      uid: this.data()?.uid,
      ...additionalProps,
    });
  }
}
